<script setup lang="ts">
import { CfgButton, CfgTypography, CfgTypographySizes } from '@cfgtech/ui'

const { handleRegister } = useCta()
</script>

<template>
  <div class="-mx-4 -mb-5 grid gap-5 rounded-xl bg-brand-dark p-5 sm:-mx-8 lg:grid-cols-5 lg:p-6">
    <div class="flex items-start gap-2 text-white lg:col-span-3 lg:gap-4">
      <img
        alt="Lock"
        class="w-[32px] lg:w-[53px]"
        height="86"
        src="public/lock.png"
        width="71"
      >

      <CfgTypography
        class="text-balance !font-extrabold"
        :size="CfgTypographySizes.subtitle"
      >
        {{ $t('issue.ctaBanner.title') }}
      </CfgTypography>
    </div>

    <div class="self-center lg:col-span-2">
      <CfgButton
        class="w-full rounded-full"
        small
        variant="orange"
        @click="handleRegister"
      >
        <CfgTypography
          class="text-balance !font-semibold"
        >
          {{ $t('issue.ctaBanner.createFreeAccount') }}
        </CfgTypography>
      </CfgButton>
    </div>
  </div>
</template>
